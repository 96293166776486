// src/components/PageFooter.js

import React from 'react';
import './PageFooter.css';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import logo from '../images/logo-footer.png';

const PageFooter = () => {
  return (
    <div className="page-footer">
      <div className="page-footer-content">
        <img src={logo} alt="ZoomLion Logo" className="page-footer-logo" />
        <div className="page-footer-info">
          <div className="page-footer-social-media">
            <a href="https://www.facebook.com/ZoomlionLatam" className="page-footer-icon-wrapper">
              <FaFacebookF className="page-footer-social-icon" />
            </a>
            <a href="https://www.instagram.com/zoomlionlatam/" className="page-footer-icon-wrapper">
              <FaInstagram className="page-footer-social-icon" />
            </a>
            <a href="https://www.linkedin.com/company/zoomlion-latam/" className="page-footer-icon-wrapper">
              <FaLinkedinIn className="page-footer-social-icon" />
            </a>
            <a href="https://www.youtube.com/@zoomlionglobal706" className="page-footer-icon-wrapper">
              <FaYoutube className="page-footer-social-icon" />
            </a>
          </div>
        </div>
      </div>
      <p>© 2024 ZoomLion Heavy Industry SAC</p>
      {/* Enlace al sitio de Mirage */}
      <a href="https://www.mirageconsultores.com" className="page-footer-link">
        Página desarrollada por Mirage | Agencia & Consultora
      </a>
    </div>
  );
};

export default PageFooter;
