// src/components/HomeActionButton.js

import React from 'react';
import './HomeActionButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

const HomeActionButton = () => {
    return (
        <Link to="/productos" className="home-action-button-container">
            <div className="home-action-button-content">
                <span>VER MAQUINARIA</span>
            </div>
            <div className="home-action-button-arrow">
                <FontAwesomeIcon icon={faAngleRight} className="home-action-button-arrow-icon" />
            </div>
        </Link>
    );
};

export default HomeActionButton;
