import React from 'react';
import { ScrollParallax } from 'react-just-parallax';
import './Nosotros.css';

export default function Nosotros() {
  return (
    <div className="wrapper">
      {/* Section 1 - Red Background */}
      <ScrollParallax strength={2.0} scale={[1, 1.8]}>
        <div className="box red">
          <h2>Sección 1</h2>
          <ScrollParallax strength={1.2} scale={[1, 1.4]}>
            <div className="small-box yellow"></div>
          </ScrollParallax>
          <ScrollParallax strength={1.4} scale={[1, 1.6]}>
            <div className="small-box blue"></div>
          </ScrollParallax>
        </div>
      </ScrollParallax>

      {/* Section 2 - Green Background */}
      <ScrollParallax strength={1.5} scale={[1, 1.5]}>
        <div className="box green">
          <h2>Sección 2</h2>
          <ScrollParallax strength={1.2} scale={[1, 1.4]}>
            <div className="small-box red"></div>
          </ScrollParallax>
          <ScrollParallax strength={1.4} scale={[1, 1.6]}>
            <div className="small-box yellow"></div>
          </ScrollParallax>
        </div>
      </ScrollParallax>

      {/* Section 3 - Blue Background */}
      <ScrollParallax strength={2.5} scale={[1, 2]} rotate={[0, 20]}>
        <div className="box blue">
          <h2>Sección 3</h2>
          <ScrollParallax strength={1.2} scale={[1, 1.4]}>
            <div className="small-box green"></div>
          </ScrollParallax>
          <ScrollParallax strength={1.4} scale={[1, 1.6]}>
            <div className="small-box red"></div>
          </ScrollParallax>
        </div>
      </ScrollParallax>

      {/* Section 4 - Yellow Background */}
      <ScrollParallax strength={1.8} scale={[1, 1.6]} rotate={[0, -20]}>
        <div className="box yellow">
          <h2>Sección 4</h2>
          <ScrollParallax strength={1.2} scale={[1, 1.4]}>
            <div className="small-box blue"></div>
          </ScrollParallax>
          <ScrollParallax strength={1.4} scale={[1, 1.6]}>
            <div className="small-box green"></div>
          </ScrollParallax>
        </div>
      </ScrollParallax>
    </div>
  );
}
