import React, { Suspense } from 'react';
import PageFooter from '../components/PageFooter'; // Assuming you have this component available
import './Concurso.css'; // The corresponding CSS file for Concurso

const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));

const Concurso = () => {
  return (
    <div className="concurso-page">
      <div className="concurso-page-banner">
        <Suspense fallback={<div>Cargando Banner...</div>}>
          <LazyPageBanner />
        </Suspense>
      </div>

      {/* Términos y Condiciones Section */}
      <div className="concurso-page-terminos-container">
        <div className="concurso-page-terminos">
          <h2>Términos y Condiciones</h2>
          <p>
            1. Sorteo aplicable sólo para Colombia.<br/>
            2. Los participantes deberán ser mayores de 18 años para ser considerados elegibles para ganar.<br/>
            3. Sólo participarán las fotos que sean publicadas entre el miércoles 25 de septiembre hasta el domingo 29 de septiembre a las 10:00pm (hora de Bogotá D.C., Colombia).<br/>
            4. Todo usuario que no siga la totalidad de pasos quedará automáticamente eliminado del concurso.<br/>
            5. Sólo existirá un ganador. El ganador será elegido de la foto con mayores likes en las historias de Zoomlion Colombia y será contactado por mensajes directos de Instagram el 01 de octubre.<br/>
            6. Instagram no tiene responsabilidades en este sorteo y los datos que se recolecten serán gestionados por Zoomlion Colombia S.A.S.<br/>
            7. El premio consiste en un modelo a escala 1:43 de una excavadora Zoomlion Modelo ZE245G.
          </p>
        </div>
      </div>

      <div className="concurso-page-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default Concurso;
