import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeLineaSliderDesktop.css';
import nextArrow from '../../../../images/lineaslider/next-desktop.webp';
import prevArrow from '../../../../images/lineaslider/prev-desktop.webp';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-linea-slider-desktop-arrow home-linea-slider-desktop-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="home-linea-slider-desktop-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-linea-slider-desktop-arrow home-linea-slider-desktop-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="home-linea-slider-desktop-arrow-image" />
    </div>
  );
}

const HomeLineaSliderDesktop = ({ lineas, onLineaChange, sliderRef, loading }) => {
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleLineaClick = (index) => {
    setSelectedLinea(index);
    onLineaChange(index);
    setClickedIndex(index);
    setTimeout(() => setClickedIndex(null), 300);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Always show 6 slides
    slidesToScroll: 1,
    arrows: true, // Show arrows always
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="home-linea-slider-desktop-wrapper">
      <div className="home-linea-slider-desktop">
        {!loading && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {lineas.map((linea, index) => (
              <div key={linea.id} onClick={() => handleLineaClick(index)}>
                <button
                  className={`home-linea-slider-desktop-slide-button ${clickedIndex === index ? 'clicked' : ''}`}
                >
                  <div
                    className={`home-linea-slider-desktop-item ${
                      selectedLinea === index ? 'selected' : ''
                    }`}
                  >
                    <div className="home-linea-slider-desktop-image-wrapper">
                      <img
                        src={linea.image}
                        alt={linea.nombre}
                        className="home-linea-slider-desktop-image"
                      />
                    </div>
                    <h3 className="home-linea-slider-desktop-label">{linea.nombre}</h3>
                  </div>
                </button>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default HomeLineaSliderDesktop;
