import React, { useState, useEffect } from 'react';
import './ProductListDesktop.css';

function ProductListDesktop({ lineas, selectedSerie, setSelectedSerie }) {
  const [expandedLineaId, setExpandedLineaId] = useState(null);
  const [selectedSerieId, setSelectedSerieId] = useState(null);

  const sortedLineas = lineas.sort((a, b) => a.posicion - b.posicion);

  useEffect(() => {
    if (selectedSerie) {
      setSelectedSerieId(selectedSerie.id);
      setExpandedLineaId(lineas.find(linea => linea.series.some(serie => serie.id === selectedSerie.id))?.id);
    }
  }, [selectedSerie, lineas]);

  const handleLineaClick = (lineaId) => {
    if (expandedLineaId === lineaId) {
      setExpandedLineaId(null);
    } else {
      setExpandedLineaId(lineaId);
    }
  };

  const handleSerieClick = (serie) => {
    setSelectedSerie(serie);
    setSelectedSerieId(serie.id);
  };

  const handleReset = () => {
    setSelectedSerie(null);
    setExpandedLineaId(null);
    setSelectedSerieId(null);
  };

  return (
    <div className="product-list-desktop-container">
      <ul className="product-list-desktop-list">
        <h2 className="product-list-desktop-title">BUSCAR</h2>
        {sortedLineas.map((linea) => (
          <li key={linea.id}>
            <div
              className={`product-list-desktop-linea-header ${
                expandedLineaId === linea.id ? 'selected-linea' : ''
              }`}
              onClick={() => handleLineaClick(linea.id)}
            >
              <p>{linea.nombre}</p>
              <span className="material-icons">expand_more</span>
            </div>
            {expandedLineaId === linea.id && (
              <ul className="product-list-desktop-series-list">
                {linea.series
                  .sort((a, b) => a.posicion - b.posicion)
                  .map((serie) => (
                    <li
                      key={serie.id}
                      onClick={() => handleSerieClick(serie)}
                      className={selectedSerieId === serie.id ? 'selected-serie' : ''}
                    >
                      {serie.nombre}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      <button className="product-list-desktop-reset-button" onClick={handleReset}>
        RESTABLECER
      </button>
    </div>
  );
}

export default ProductListDesktop;
