import React, { useState, useEffect } from 'react';
import HomeNoticiasSectionDesktop from './HomeNoticiasSectionDesktop/HomeNoticiasSectionDesktop';
import HomeNoticiasSectionMobile from './HomeNoticiasSectionMobile/HomeNoticiasSectionMobile';
import './HomeNoticiasSection.css';

const HomeNoticiasSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Function to handle window resizing
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="home-noticias-section-container">
      {isMobile ? (
        <HomeNoticiasSectionMobile />  
      ) : (
        <HomeNoticiasSectionDesktop />  
      )}
    </div>
  );
};

export default HomeNoticiasSection;
