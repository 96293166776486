import React, { useState } from 'react';
import './OfertaLista.css';

const OfertaLista = ({ paises, onPaisChange }) => {
  const [selectedPais, setSelectedPais] = useState(null);

  const handlePaisClick = (pais) => {
    setSelectedPais(pais);
    onPaisChange(pais);
  };

  return (
    <div className="oferta-lista-container">
      <h3 className="oferta-lista-titulo">Selecciona un país</h3>
      <ul className="oferta-lista-items">
        {paises.map((pais) => (
          <li
            key={pais}
            onClick={() => handlePaisClick(pais)}
            className={`oferta-lista-item ${selectedPais === pais ? 'selected-item' : ''}`}
          >
            {pais}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OfertaLista;
