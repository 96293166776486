import React from 'react';
import ProductListDesktop from './ProductListDesktop';
import ProductListMobile from './ProductListMobile';

function useWindowWidth() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

function ProductListWrapper({ lineas, selectedSerie, setSelectedSerie }) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;

  return isMobile ? (
    <ProductListMobile
      lineas={lineas}
      selectedSerie={selectedSerie}
      setSelectedSerie={setSelectedSerie}
    />
  ) : (
    <ProductListDesktop
      lineas={lineas}
      selectedSerie={selectedSerie}
      setSelectedSerie={setSelectedSerie}
    />
  );
}

export default ProductListWrapper
