import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './HomeContactoCardDesktop.css';

const HomeContactoCardDesktop = ({ selectedCountry, onCountryChange, countries }) => {
  const handleCountryChange = (event) => {
    const countryName = event.target.value;
    const selected = countries.find(country => country.name === countryName);
    onCountryChange(selected);
  };

  return (
    <>
      <div className="home-contacto-desktop-card-header">
        <h3 className="home-contacto-desktop-card-title">
          Creamos valor para nuestros clientes en 19 países de Latinoamérica
        </h3>
      </div>
      <div className="home-contacto-desktop-card">
        <select
          value={selectedCountry?.name || ''}
          onChange={handleCountryChange}
          className="home-contacto-desktop-card-selector"
        >
          {countries.map((country) => (
            <option key={country.name} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>

        {selectedCountry ? (
          <div className="home-contacto-desktop-card-details">
            <ul>
              <li>Representante: {selectedCountry.representante}</li>
              <li>Teléfono: +{selectedCountry.telefono}</li>
              <li>Dirección: {selectedCountry.direccion}</li>
              <li>Correo: {selectedCountry.email}</li>
            </ul>
          </div>
        ) : (
          <div>No hay información disponible</div>
        )}

        <div className="home-contacto-desktop-card-button-container">
          <div
            className="home-contacto-desktop-card-button"
            onClick={() => (window.location.href = '/contacto')}
          >
            <div className="home-contacto-desktop-card-button-content">
              <span>CONTÁCTANOS</span>
            </div>
            <div className="home-contacto-desktop-card-button-arrow">
              <FaMapMarkerAlt className="home-contacto-desktop-card-button-arrow-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeContactoCardDesktop;
