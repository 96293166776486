import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Home.css';
import PageFooter from '../components/PageFooter';
import HomeBannerSection from '../components/Home/HomeBannerSection/HomeBannerSection';
import HomeShowcaseSection from '../components/Home/HomeShowcaseSection/HomeShowcaseSection';
import HomeNoticiasSection from '../components/Home/HomeNoticiasSection/HomeNoticiasSection';
import HomeContactoSection from '../components/Home/HomeContactoSection/HomeContactoSection';

const Home = ({ fullpageApi }) => {
  // Ref for section 1
  const section1Ref = useRef(null);

  // Create a ref for the HomeContact section
  const contactSectionRef = useRef(null);

  // Get the current location
  const location = useLocation();

  // Update title and meta description on mount
  useEffect(() => {
    document.title = 'Zoomlion Latinoamérica';

    // Update the meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      'Descubre la maquinaria de alta calidad de Zoomlion Latinoamérica, diseñada con tecnología avanzada, sustentabilidad y experiencia. Soluciones innovadoras para la construcción, minería y agricultura.';
  }, []);

  // Move to section 1 whenever the path is '/'
  useEffect(() => {
    if (location.pathname === '/') {
      if (fullpageApi && typeof fullpageApi.moveTo === 'function') {
        fullpageApi.moveTo(1);
      }
    }
  }, [location.pathname, fullpageApi]);

  // Function to handle scroll to the HomeContact section
  const scrollToContact = () => {
    if (fullpageApi && typeof fullpageApi.moveTo === 'function') {
      fullpageApi.moveTo(3); // Assuming section 3 is the contact section
    } else if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="section section1" ref={section1Ref}>
        <HomeBannerSection scrollToContact={scrollToContact} />
      </div>
      <div className="section section2">
        <HomeShowcaseSection />
      </div>
      <div className="section section3" ref={contactSectionRef}>
        <HomeContactoSection />
      </div>
      <div className="section section4">
        <HomeNoticiasSection />
      </div>
      <div className="section fp-auto-height">
        <PageFooter />
      </div>
    </>
  );
};

export default Home;
