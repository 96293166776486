// src/components/Modelo/ModeloDestacadoMobile/ModeloDestacadoMobile.js
import React, { useEffect, useState } from 'react';
import ModeloDestacadoMobileCard from './ModeloDestacadoMobileCard';
import './ModeloDestacadoMobile.css'; 
import Slider from 'react-slick';  // Importamos el componente Slider de react-slick
import 'slick-carousel/slick/slick.css'; // Importamos los estilos de Slick
import 'slick-carousel/slick/slick-theme.css'; 

const ModeloDestacadoMobile = ({ linea }) => {
  const [destacados, setDestacados] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDestacados = async () => {
      if (!linea || !linea.id) {
        setError('No se pudo encontrar la línea seleccionada.');
        return;
      }

      try {
        // Agregamos los campos 'serie' e 'imagen_card' al populate
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?filters[id]=${linea.id}&populate=destacados.serie,destacados.imagen_card`);
        const data = await response.json();

        if (!response.ok || !data.data || data.data.length === 0) {
          throw new Error('Error fetching destacados');
        }

        const destacadosData = data.data[0].attributes.destacados.data.map(destacado => destacado.attributes);
        setDestacados(destacadosData.slice(0, 3));  // Limitamos a los 3 primeros
      } catch (error) {
        console.error('Error fetching destacados:', error);
        setError('Error loading destacados.');
      }
    };

    fetchDestacados();
  }, [linea]);

  if (error) {
    return <div className="modelo-destacado-mobile-error">{error}</div>;
  }

  // Configuración de React Slick
  const settings = {
    dots: true, // Muestra puntos de navegación
    infinite: true, // Ciclo infinito de las diapositivas
    speed: 500, // Velocidad de transición en ms
    slidesToShow: 1, // Mostrar solo una tarjeta a la vez
    slidesToScroll: 1, // Avanzar una tarjeta a la vez
  };

  return (
    <div className="modelo-destacado-mobile-section">
      <div className="modelo-destacado-mobile-container">
        <h3 className="modelo-destacado-mobile-title">Productos Recomendados</h3>
        <div className="modelo-destacado-mobile-slider-wrapper">
          <Slider {...settings}>
            {destacados.length > 0 ? (
              destacados.map((modelo, index) => (
                <div key={index}>
                  <ModeloDestacadoMobileCard 
                    modelo={modelo.nombre}
                    slug={modelo.slug}
                    imagenCard={modelo.imagen_card?.data?.attributes?.url}  // Pasamos la URL de la imagen
                  />
                </div>
              ))
            ) : (
              <div>No hay modelos destacados.</div>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ModeloDestacadoMobile;
