import React, { useState } from 'react';

const LazyImage = ({ src, alt, className }) => {
  const [imageError, setImageError] = useState(false); // Estado para manejar error de carga
  const imgNotAvailable = '../../images/General/image_not_available.webp'; // Imagen de respaldo

  const handleError = () => {
    setImageError(true); // Si hay error, usa imagen por defecto
  };

  return (
    <img
      src={imageError ? imgNotAvailable : src}  // Usa la imagen de respaldo si hay error
      alt={alt}
      className={className}
      loading="lazy"
      onError={handleError}  // Maneja el error de carga
    />
  );
};

export default LazyImage;
