import React, { useState, useEffect } from 'react';
import './ProductListMobile.css';

function ProductListMobile({ lineas, selectedSerie, setSelectedSerie }) {
  const [selectedLineaId, setSelectedLineaId] = useState(null);
  const [availableSeries, setAvailableSeries] = useState([]);

  useEffect(() => {
    if (selectedLineaId) {
      const selectedLinea = lineas.find((linea) => linea.id === selectedLineaId);
      setAvailableSeries(selectedLinea ? selectedLinea.series : []);
    } else {
      setAvailableSeries([]);
    }
  }, [selectedLineaId, lineas]);

  const handleLineaChange = (event) => {
    setSelectedLineaId(Number(event.target.value));
    setSelectedSerie(null); // Reset the selected series when a new linea is selected
  };

  const handleSerieChange = (event) => {
    const selectedSerieId = Number(event.target.value);
    const serie = availableSeries.find((serie) => serie.id === selectedSerieId);
    setSelectedSerie(serie || null);
  };

  return (
    <div className="product-list-mobile-container">
      <h2 className="product-list-title">Buscar</h2>
      
      {/* Selector for Líneas */}
      <div className="product-list-mobile-select-container">
        <label htmlFor="linea-select">Línea:</label>
        <select
          id="linea-select"
          value={selectedLineaId || ''}
          onChange={handleLineaChange}
          className="product-list-mobile-select"
        >
          <option value="" disabled>
            Selecciona una línea
          </option>
          {lineas.map((linea) => (
            <option key={linea.id} value={linea.id}>
              {linea.nombre}
            </option>
          ))}
        </select>
      </div>
      
      {/* Selector for Series */}
      <div className="product-list-mobile-select-container">
        <label htmlFor="serie-select">Serie:</label>
        <select
          id="serie-select"
          value={selectedSerie?.id || ''}
          onChange={handleSerieChange}
          disabled={!selectedLineaId}
          className="product-list-mobile-select"
        >
          <option value="" disabled>
            Selecciona una serie
          </option>
          {availableSeries.map((serie) => (
            <option key={serie.id} value={serie.id}>
              {serie.nombre}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default ProductListMobile;
