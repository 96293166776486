import React, { useState, useEffect, Suspense } from 'react';
import './Ofertas.css';
import PageFooter from '../components/PageFooter';
import OfertaLista from '../components/Oferta/OfertaLista';
import OfertaGrid from '../components/Oferta/OfertaGrid';
import OfertaForm from '../components/Oferta/OfertaForm';

const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));

const Ofertas = () => {
  const [convocatorias, setConvocatorias] = useState([]);
  const [selectedPais, setSelectedPais] = useState(null);
  const [selectedConvocatoria, setSelectedConvocatoria] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConvocatorias = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/convocatorias`);
        const result = await response.json();
        if (Array.isArray(result.data)) {
          const formattedData = result.data.map((convocatoria) => ({
            id: convocatoria.id,
            pais: convocatoria.attributes.pais,
            ciudad: convocatoria.attributes.ciudad,
            titulo: convocatoria.attributes.titulo,
            codigo: convocatoria.attributes.codigo,
            fin: convocatoria.attributes.fin,
          }));
          setConvocatorias(formattedData);
        }
      } catch (error) {
        console.error('Error al obtener las convocatorias:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchConvocatorias();
  }, []);

  const filteredConvocatorias = selectedPais
    ? convocatorias.filter((convocatoria) => convocatoria.pais === selectedPais)
    : convocatorias;

  const paisesDisponibles = ["Perú", "Chile", "Colombia", "Panamá", "México"];

  const handlePaisChange = (pais) => {
    setSelectedPais(pais);
  };

  const handlePostular = (convocatoria) => {
    setSelectedConvocatoria(convocatoria);
  };

  const handleCloseForm = () => {
    setSelectedConvocatoria(null); // Reset the selected convocatoria
  };

  return (
    <div className="oferta-page-container">
      <div className="oferta-page-banner">
        <Suspense fallback={<div>Loading Banner...</div>}>
          <LazyPageBanner />
        </Suspense>
      </div>
      <div className="oferta-page-content">
        <OfertaLista 
          paises={paisesDisponibles.sort()} 
          onPaisChange={handlePaisChange} 
        />
        <OfertaGrid 
          convocatorias={filteredConvocatorias} 
          onPostular={handlePostular} 
          loading={loading}
        />
      </div>
      {selectedConvocatoria && (
        <div className="oferta-page-form-section">
          <OfertaForm selectedOficina={selectedConvocatoria} onClose={handleCloseForm} />
        </div>
      )}
      <div className="oferta-page-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default Ofertas;
