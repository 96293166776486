// src/components/Modelo/ModeloCotizacion.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './ModeloCotizacion.css';

const ModeloCotizacion = ({ modelo }) => {
  const navigate = useNavigate(); // Definir navigate

  const handleCotizacionClick = () => {
    // GA4 event for requesting a quote
    window.gtag('event', 'request_quote', {
      event_category: 'engagement',
      event_label: `Solicitud de Cotización - ${modelo.nombre}`,
      value: 1
    });

    // Navegar a la página de contacto
    navigate('/contacto', { state: { modelo } });
  };

  return (
    <div className="modelo-cotizacion">
      <div className="modelo-cotizacion-button-container" onClick={handleCotizacionClick}>
        <div className="modelo-cotizacion-button-content">
          <span>SOLICITAR COTIZACIÓN</span>
        </div>
        <div className="modelo-cotizacion-button-arrow">
          <FontAwesomeIcon icon={faAngleRight} className="modelo-cotizacion-button-arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default ModeloCotizacion;
