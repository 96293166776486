import React, { useState } from 'react';
import './NoticiaFiltro.css';

const NoticiaFiltro = ({ onSearch, onSort }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  const handleSort = (e) => {
    onSort(e.target.value);
  };

  return (
    <div className="noticias-page-filtro">
      <input
        type="text"
        placeholder="Buscar por título"
        value={searchTerm}
        onChange={handleSearch}
        className="noticias-page-filtro-buscar"
      />
      <select onChange={handleSort} className="noticias-page-filtro-ordenar">
        <option value="reciente">Más recientes</option>
        <option value="antigua">Más antiguas</option>
      </select>
    </div>
  );
};

export default NoticiaFiltro;
