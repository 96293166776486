// src/components/HomeBannerDesktop/HomeBannerDesktop.js

import React from 'react';
import Slider from 'react-slick';
import './HomeBannerDesktop.css';

// Image imports
import bannerImage1 from '../../../../images/HomeBannerDesktop/home-banner-desktop-1.webp';
import bannerImage2 from '../../../../images/HomeBannerDesktop/home-banner-desktop-2.webp';
import bannerImage3 from '../../../../images/HomeBannerDesktop/home-banner-desktop-3.webp';
import HomeActionButton from '../../HomeActionButton'; // Import the HomeActionButton

const HomeBannerDesktop = ({ scrollToContact }) => {
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable arrows
    autoplay: true, // Autoplay the slides
    autoplaySpeed: 5000, // Change every 5 seconds
    fade: true, // Fade transition between slides
  };

  return (
    <div className="home-banner-desktop-container">
      <Slider {...settings}>
        <div className="home-banner-desktop-slide">
          <img src={bannerImage1} alt="Home banner 1" className="home-banner-desktop-image" />
        </div>
        <div className="home-banner-desktop-slide">
          <img src={bannerImage2} alt="Home banner 2" className="home-banner-desktop-image" />
        </div>
        <div className="home-banner-desktop-slide">
          <img src={bannerImage3} alt="Home banner 3" className="home-banner-desktop-image" />
        </div>
      </Slider>

      {/* Content that stays on top of the carousel */}
      <div className="home-banner-desktop-content">
        <h2 className="home-banner-desktop-vision-label">VISIÓN QUE CREA FUTURO</h2>
        <p className="home-banner-desktop-vision-sublabel">
          Maquinaria de alta calidad que integran tecnología, experiencia y sustentabilidad.
        </p>
        <div className="home-banner-desktop-action-container">
          <HomeActionButton onClick={scrollToContact} />
        </div>
      </div>
    </div>
  );
};

export default HomeBannerDesktop;
