// src/components/HomeNoticiasSectionDesktopCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomeNoticiasSectionDesktopCard.css';

const HomeNoticiasSectionDesktopCard = ({ noticia }) => {
  return (
    <div className="home-noticias-section-desktop-card-container">
      <div className="home-noticias-section-desktop-card-image-container">
        {noticia.previsualizacion ? (
          <img src={noticia.previsualizacion} alt={noticia.titulo} className="home-noticias-section-desktop-card-image" />
        ) : (
          <div className="home-noticias-section-desktop-card-placeholder">No Image Available</div>
        )}
      </div>
      <h3 className="home-noticias-section-desktop-card-title">{noticia.titulo}</h3>
      <div className="home-noticias-section-desktop-card-button-wrapper">
        <Link to={`/noticias/${noticia.slug}`} className="home-noticias-section-desktop-card-button">
          IR A NOTICIA
        </Link>
      </div>
    </div>
  );
};

export default HomeNoticiasSectionDesktopCard;
