import React, { useEffect, useState, Suspense } from 'react';
import PageFooter from '../components/PageFooter'; 

import { useParams } from 'react-router-dom';
import './Noticia.css'; // Importar el archivo CSS para las clases

const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));

const Noticia = () => {
  const { slug } = useParams(); // Obtener el slug de la noticia desde la URL
  const [noticia, setNoticia] = useState(null); // Estado para almacenar los datos de la noticia
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para el manejo de errores

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/noticias?filters[slug][$eq]=${slug}&populate=imagen,previsualizacion`
        );
        const data = await response.json();

        if (data && data.data && data.data.length > 0) {
          setNoticia(data.data[0]); // Guardar la primera noticia que coincida con el slug
        } else {
          setError('Noticia no encontrada');
        }
      } catch (error) {
        setError('Error al cargar la noticia');
      } finally {
        setLoading(false);
      }
    };

    fetchNoticia();
  }, [slug]); // Ejecutar el efecto cuando el slug cambie

  if (loading) {
    return <div className="noticias-page-loading">Cargando...</div>;
  }

  if (error) {
    return <div className="noticias-page-error">{error}</div>;
  }

  if (!noticia) {
    return <div className="noticias-page-not-found">No se encontró la noticia.</div>;
  }

  // Acceder a los campos de la noticia
  const {
    titulo,
    bajada,
    descripcion,
    cuerpo,
    imagen,
  } = noticia.attributes;

  return (
    <div className="noticia-page-container">
        <div className="noticia-page-banner">
          <Suspense fallback={<div>Loading Banner...</div>}>
            <LazyPageBanner />
          </Suspense>
        </div>
        <div className="noticia-page-content">
      <h1 className="noticia-page-titulo">{titulo}</h1>
      {imagen && (
        <img
          src={imagen.data.attributes.url}
          alt={titulo}
          className="noticia-page-imagen"
        />
      )}
      <h2 className="noticia-page-bajada">{bajada}</h2>
      <p className="noticia-page-descripcion">{descripcion}</p>
      <div className="noticia-page-cuerpo">
        <p>{cuerpo}</p>
      </div>
      </div>
      <div className="noticia-page-footer">
          <PageFooter />
        </div>
    </div>
  );
};

export default Noticia;
