import React from 'react';
import OfertaCard from './OfertaCard';
import './OfertaGrid.css';

const OfertaGrid = ({ convocatorias = [], onPostular }) => { // Asegúrate de que onPostular esté aquí
  return (
    <div className="oferta-grid-container">
      {convocatorias.length > 0 ? (
        convocatorias.map((convocatoria) => (
          <OfertaCard
            key={convocatoria.id}
            convocatoria={convocatoria}
            onPostular={onPostular} // Pasar la función a cada card
          />
        ))
      ) : (
        <p className="oferta-grid-no-convocatorias">No hay convocatorias disponibles para este país.</p>
      )}
    </div>
  );
};

export default OfertaGrid;
