import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProductListWrapper from '../components/Productos/ProductListWrapper'; // Usar el nuevo Wrapper
import ProductGrid from '../components/Productos/ProductGrid';
import PageBanner from '../components/PageBanner';
import PageFooter from '../components/PageFooter';
import './Productos.css';

function Productos() {
  const location = useLocation();
  const { linea, serie } = location.state || {};

  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState(linea || null);
  const [selectedSerie, setSelectedSerie] = useState(serie || null);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Cambiar el título y la meta descripción al montar el componente
  useEffect(() => {
    document.title = "Zoomlion Latinoamérica | Productos";

    // Actualizar la meta descripción
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Consulta el catálogo completo de maquinaria Zoomlion disponible para Latinoamérica.";
  }, []);

  useEffect(() => {
    if (location.state) {
      setSelectedLinea(location.state.linea || null);
      setSelectedSerie(location.state.serie || null);
    }
  }, [location]);

  useEffect(() => {
    const fetchLineas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate=series`);
        const data = await response.json();
        if (data && data.data) {
          const fetchedLineas = data.data.map(linea => ({
            id: linea.id,
            nombre: linea.attributes.nombre,
            posicion: linea.attributes.posicion,
            series: linea.attributes.series?.data.map(serie => ({
              id: serie.id,
              nombre: serie.attributes.nombre,
              posicion: serie.attributes.posicion
            })) || []
          }));

          const sortedLineas = fetchedLineas.sort((a, b) => a.posicion - b.posicion);
          sortedLineas.forEach(linea => {
            linea.series = linea.series.sort((a, b) => a.posicion - b.posicion);
          });

          setLineas(sortedLineas);

          // Set default values if not passed
          if (!selectedLinea && sortedLineas.length > 0) {
            setSelectedLinea(sortedLineas[0]);
            setSelectedSerie(sortedLineas[0].series[0] || null);
          }
        } else {
          console.error("Error al obtener las líneas:", data);
        }
      } catch (error) {
        console.error("Error fetching lineas:", error);
      }
    };

    fetchLineas();
  }, [selectedLinea, selectedSerie]);

  useEffect(() => {
    const fetchModels = async () => {
      if (selectedSerie) {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/modelos?filters[serie][id]=${selectedSerie.id}&populate=especificaciones,imagen_card,slug`);
          const data = await response.json();

          if (!response.ok || !data.data) {
            throw new Error(`Error fetching models for Serie ID: ${selectedSerie.id}`);
          }

          const fetchedModels = data.data.map(model => {
            const imageUrl = model.attributes.imagen_card?.data?.attributes?.url;

            return {
              id: model.id,
              slug: model.attributes.slug,
              name: model.attributes.nombre,
              specs: model.attributes.especificaciones?.slice(0, 3).map(spec => ({
                name: spec.name,
                value: spec.value
              })) || [],
              image: imageUrl || '/images/default-banner.webp'
            };
          });

          setModels(fetchedModels);
        } catch (error) {
          console.error("Error fetching models:", error);
          setError("Error loading model data.");
        } finally {
          setLoading(false);
        }
      } else {
        setModels([]);
      }
    };

    fetchModels();
  }, [selectedSerie]);

  return (
    <div className="products-page-container">
      <div className="products-page-banner">
        <Suspense fallback={<div>Loading Banner...</div>}>
          <PageBanner />
        </Suspense>
      </div>
      <div className="products-page-content">
        <ProductListWrapper
          lineas={lineas}
          selectedLinea={selectedLinea}
          setSelectedLinea={setSelectedLinea}
          selectedSerie={selectedSerie}
          setSelectedSerie={setSelectedSerie}
        />
        {selectedLinea && selectedSerie && (
          <ProductGrid
            models={models}
            loading={loading}
            lineaName={selectedLinea?.nombre || 'N/A'}
            serieName={selectedSerie?.nombre || 'N/A'}
          />
        )}
      </div>
      <div className="products-page-footer">
        <PageFooter />
      </div>
    </div>
  );
}

export default Productos;
