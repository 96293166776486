import React, { useState, useEffect, useRef } from 'react';
import './OfertaForm.css';
import { RevolvingDot } from 'react-loader-spinner';
import { Button, TextField, Checkbox, FormControlLabel } from '@mui/material';

const generateCaptcha = () => {
  const charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lengthOtp = 6;
  let captcha = [];
  for (let i = 0; i < lengthOtp; i++) {
    let index = Math.floor(Math.random() * charsArray.length);
    captcha.push(charsArray[index]);
  }
  return captcha.join("");
};

const OfertaForm = ({ selectedOficina, onClose }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    correo: '',
    telefono: '',
    pais: selectedOficina ? selectedOficina.pais : '',
    ciudad: '',
    documentos: [],  // Field for multiple document uploads
  });

  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [captchaError, setCaptchaError] = useState(''); // Añadido para definir el estado de captchaError
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const thankYouMessageRef = useRef(null);

  useEffect(() => {
    if (selectedOficina) {
      setFormData((prevData) => ({
        ...prevData,
        pais: selectedOficina.pais,
      }));
    }
  }, [selectedOficina]);

  useEffect(() => {
    setCaptchaCode(generateCaptcha());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaInput = (e) => {
    setCaptchaInput(e.target.value);
    if (e.target.value === captchaCode) {
      setIsCaptchaValid(true);
      setCaptchaError(''); // Limpia el error si el captcha es correcto
    } else {
      setIsCaptchaValid(false);
      setCaptchaError('El CAPTCHA es incorrecto. Inténtalo de nuevo.'); // Establece un mensaje de error
    }
  };

  const handleFileUpload = (e) => {
    setFormData({ ...formData, documentos: [...e.target.files] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Lógica para manejar el envío del formulario
  };

  return (
    <div className="oferta-form-wrapper">
      <form onSubmit={handleSubmit} className="oferta-form-form">
        {/* Campos del formulario */}
        <div className="oferta-form-group">
          <TextField
            label="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputChange}
            fullWidth
            required
            disabled={isSending}
          />
        </div>
        <div className="oferta-form-group">
          <TextField
            label="Apellidos"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleInputChange}
            fullWidth
            required
            disabled={isSending}
          />
        </div>
        <div className="oferta-form-group">
          <TextField
            label="Correo Electrónico"
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleInputChange}
            fullWidth
            required
            disabled={isSending}
          />
        </div>

        {/* Mostrar error de CAPTCHA si existe */}
        {captchaError && <div className="oferta-form-captcha-error">{captchaError}</div>}

        <div className="oferta-form-submit-wrapper">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={isSubmitEnabled ? 'oferta-form-button-enabled' : 'oferta-form-button-disabled'}
            disabled={!isSubmitEnabled}
          >
            Enviar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className="oferta-form-button-cerrar"
          >
            Cerrar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OfertaForm;
