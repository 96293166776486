import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Submenu.css';

const Submenu = ({ onClose }) => {
  const [lineas, setLineas] = useState([]);
  const [activeLinea, setActiveLinea] = useState(null);
  const [activeSerie, setActiveSerie] = useState(null);
  const [modelos, setModelos] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLineas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate=navegacion,series,series.imagen_landing`);
        const data = await response.json();
        if (data && data.data) {
          const fetchedLineas = data.data.map((item) => ({
            id: item.id,
            nombre: item.attributes.nombre,
            image: item.attributes.navegacion?.data?.attributes?.url || "",
            posicion: item.attributes.posicion,  
            series: item.attributes.series?.data.map((serie) => ({
              id: serie.id,
              nombre: serie.attributes.nombre,
              image: serie.attributes.imagen_landing?.data?.[0]?.attributes?.url || "",
              posicion: serie.attributes.posicion,  
            })) || []
          }));

          const sortedLineas = fetchedLineas.sort((a, b) => a.posicion - b.posicion);

          sortedLineas.forEach(linea => {
            linea.series = linea.series.sort((a, b) => a.posicion - b.posicion);
          });

          setLineas(sortedLineas);
          setLoading(false);
        } else {
          console.error("Error en la estructura de los datos de lineas:", data);
        }
      } catch (error) {
        console.error("Error al obtener las lineas:", error);
      }
    };

    fetchLineas();
  }, []);

  const handleLineaHover = (linea) => {
    setActiveLinea(linea);
    setActiveSerie(null);
    setModelos([]);
  };

  const handleSerieHover = async (serie) => {
    setActiveSerie(serie);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/series/${serie.id}?populate=modelos`);
      const data = await response.json();
      if (data && data.data) {
        const fetchedModelos = data.data.attributes.modelos?.data.map((modelo) => ({
          id: modelo.id,
          nombre: modelo.attributes.nombre,
          slug: modelo.attributes.slug,  // Incluir el slug
        })) || [];
        setModelos(fetchedModelos);
      } else {
        console.error("Error en la estructura de los datos de la serie:", data);
      }
    } catch (error) {
      console.error("Error al obtener los modelos:", error);
    }
  };

  const handleSerieClick = (serie) => {
    if (activeLinea && serie) {
      setActiveSerie(serie);
      navigate('/productos', {
        state: {
          linea: activeLinea,
          serie: serie,
        },
      });
      onClose();
    } else {
      console.error("No se ha seleccionado ninguna línea o serie.");
    }
  };

  const handleSeeMoreClick = () => {
    if (activeLinea && activeSerie) {
      navigate('/productos', {
        state: {
          linea: activeLinea,
          serie: activeSerie,
        },
      });
      onClose();
    } else {
      console.error("No se ha seleccionado ninguna línea o serie.");
    }
  };

  // Modificar para usar el slug en lugar del id
  const handleModelClick = (modelSlug) => {

      // GA4 event for selecting a model
  window.gtag('event', 'select_model', {
    model_slug: modelSlug,
    event_category: 'navigation',
    event_label: 'Submenu Model Click',
    value: 1
  });

    navigate(`/modelo/${modelSlug}`);  // Usar el slug para la navegación
    onClose();
  };

  return (
    <div className="navbar-submenu">
      {loading ? (
        <div>Cargando productos...</div>
      ) : (
        <>
          <div className="navbar-submenu-column-1">
            {lineas.map((linea) => (
              <div
                key={linea.id}
                className={`navbar-submenu-linea ${activeLinea?.id === linea.id ? 'navbar-submenu-bold active' : ''}`} 
                onMouseEnter={() => handleLineaHover(linea)}
                onClick={() => setActiveLinea(linea)}
              >
                <span className={activeLinea?.id === linea.id ? 'navbar-submenu-bold' : ''}>{linea.nombre}</span>
                {linea.image && <img src={linea.image} alt={linea.nombre} className="navbar-submenu-icon" />}
              </div>
            ))}
          </div>
          <div className="navbar-submenu-column-2">
            {activeLinea && activeLinea.series.length > 0 ? (
              activeLinea.series.map((serie) => (
                <div
                  key={serie.id}
                  className={`navbar-submenu-serie ${activeSerie?.id === serie.id ? 'navbar-submenu-bold active' : ''}`} 
                  onClick={() => handleSerieClick(serie)}
                  onMouseEnter={() => handleSerieHover(serie)}
                >
                  <span className={activeSerie?.id === serie.id ? 'navbar-submenu-bold' : ''}>{serie.nombre}</span>
                </div>
              ))
            ) : (
              activeLinea && (
                <div className="navbar-submenu-no-series">
                  No hay series en esta línea
                </div>
              )
            )}
          </div>
          <div className="navbar-submenu-column-3">
            {activeSerie && (
              <>
                {modelos.length > 0 ? (
                  modelos.slice(0, 8).map((modelo) => (
                    <div
                      key={modelo.id}
                      className="navbar-submenu-modelo"
                      onClick={() => handleModelClick(modelo.slug)}  // Usar el slug en lugar del id
                    >
                      <span>{modelo.nombre}</span>
                    </div>
                  ))
                ) : (
                  <div className="navbar-submenu-no-modelos">
                    No hay modelos disponibles para la serie seleccionada.
                  </div>
                )}
                {modelos.length > 8 && (
                  <div className="navbar-submenu-ver-mas" onClick={handleSeeMoreClick}>
                    Ver más
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Submenu;
