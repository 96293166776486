import React, { Suspense, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ModeloHeaderDesktop.css';

const LazyImage = React.lazy(() => import('../LazyImage'));

const ModeloHeaderDesktop = ({ nombre, serie, linea, descripcion, imagenUrl }) => {
  const imagePath = '/images/ModeloBanner/model-background-desktop.webp';

  useEffect(() => {
  }, [nombre, serie, linea, descripcion, imagenUrl]);

  return (
    <div>
      <div
        className="modelo-header-desktop-banner"
        style={{
          backgroundImage: `url(${imagePath})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="modelo-header-desktop-ghost">{nombre}</div>
      </div>

      <div className="modelo-header-desktop-image-container">
          <Suspense fallback={<div>Cargando imagen...</div>}>
            <LazyImage
              src={imagenUrl}
              alt={nombre}
              className="modelo-header-desktop-image"
            />
          </Suspense>
      </div>

      {/* Segundo container con nombre, link y descripción */}
      <div className="modelo-header-desktop-info-container">
        
        {/* Contenedor izquierdo: título y serie */}
        <div className="modelo-header-desktop-texto">
          <h3 className="modelo-header-desktop-serie">
            <Link to="/productos" state={{ linea, serie }}>  
              {linea.nombre} / {serie.nombre}
            </Link>
          </h3>
          <h2 className="modelo-header-desktop-title">{nombre}</h2>
        </div>
        
        {/* Contenedor derecho: descripción */}
        <div className="modelo-header-desktop-description">
          {descripcion && <p>{descripcion}</p>}
        </div>

      </div>
    </div>
  );
};

export default ModeloHeaderDesktop;