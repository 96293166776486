import React from 'react';
import ModeloHeaderMobile from './ModeloHeaderMobile';
import ModeloHeaderDesktop from './ModeloHeaderDesktop';

const ModeloHeaderWrapper = ({ nombre, serie, linea, descripcion, imagenUrl }) => {
  const isMobile = window.innerWidth < 768;

  return (
    <>
      {isMobile ? (
        <ModeloHeaderMobile
          nombre={nombre}
          linea={linea}
          serie={serie}  // Pass serie object
          descripcion={descripcion}
          imagenUrl={imagenUrl}
        />
      ) : (
        <ModeloHeaderDesktop
          nombre={nombre}
          linea={linea}
          serie={serie}  // Pass serie object
          descripcion={descripcion}
          imagenUrl={imagenUrl}
        />
      )}
    </>
  );
};

export default ModeloHeaderWrapper;
