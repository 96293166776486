// src/components/ModeloTable.js
import React from 'react';
import './ModeloTable.css'; // Importamos los estilos

const ModeloTable = ({ specs }) => {
  // Obtenemos todas las especificaciones excepto las primeras tres
  const remainingSpecs = specs.slice(3);

  if (remainingSpecs.length === 0) {
    return <p>No hay más especificaciones disponibles.</p>;
  }

  return (
    <div className="modelo-table-container">
      <h3 className="modelo-table-title">Ventajas adicionales</h3>
      <table className="modelo-specs-table">
        <thead>
          <tr>
            <th>Especificación</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {remainingSpecs.map((spec, index) => (
            <tr key={index}>
              <td>{spec.name}</td>
              <td>{spec.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ModeloTable;
