// src/components/General/CookieConsent.js
import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import './CookieConsent.css';

const COOKIE_NAME = 'user_cookie_consent';

const CookieConsent = ({
  message = "Usamos cookies para mejorar tu experiencia.",
  buttonText = "Aceptar",
  policyLink = "/cookies/cookies.pdf",
  onAccept,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem(COOKIE_NAME);
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(COOKIE_NAME, true);
    setIsVisible(false);
    if (onAccept) onAccept();

    // Envía el evento de consentimiento a Google Tag Manager
    TagManager.dataLayer({
      dataLayer: {
        event: 'cookieConsentAccepted',
      },
    });
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-banner">
      <p className="cookie-message">
        {message}
        <a href={policyLink} target="_blank" rel="noopener noreferrer" className="cookie-link">
          Lee nuestra política de cookies aquí
        </a>
      </p>
      <button className="cookie-button" onClick={handleAccept}>{buttonText}</button>
    </div>
  );
};

export default CookieConsent;
