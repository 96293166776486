import React, { useEffect, useRef } from 'react';
import './ModeloSpecs.css'; 

const ModeloSpecs = ({ specs }) => {
  const specRef = useRef([]); // Ref para las especificaciones

  useEffect(() => {
    // Definir el observador
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.2 } // El 20% del elemento debe ser visible para activarse
    );

    // Observar cada uno de los elementos de las especificaciones
    specRef.current.forEach((spec) => {
      if (spec) {
        observer.observe(spec);
      }
    });

    // Limpieza del observador
    return () => {
      if (specRef.current) {
        specRef.current.forEach((spec) => {
          if (spec) {
            observer.unobserve(spec);
          }
        });
      }
    };
  }, []);

  // Obtenemos las primeras tres especificaciones
  const firstThreeSpecs = specs.slice(0, 3);

  return (
    <div className="modelo-specs-container">
      <h3 className="modelo-specs-title">Principales ventajas</h3>
      <div className="modelo-specs">
        {firstThreeSpecs.map((spec, index) => (
          <div
            key={index}
            className="modelo-spec"
            ref={(el) => (specRef.current[index] = el)} // Asignar ref a cada spec
          >
            <div className="modelo-spec-value">{spec.value}</div>
            <div className="modelo-spec-name">{spec.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModeloSpecs;
