import React, { useEffect, useState, useRef } from 'react';
import HomeShowcaseDesktop from './HomeShowcaseDesktop/HomeShowcaseDesktop';
import HomeShowcaseMobile from './HomeShowcaseMobile/HomeShowcaseMobile';
import './HomeShowcaseSection.css'; // Updated custom styles

function HomeShowcaseSection() {
  const [lineas, setLineas] = useState([]);
  const [currentLinea, setCurrentLinea] = useState("");
  const [loading, setLoading] = useState(true);
  const lineaSliderRef = useRef(null);
  const serieSliderRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchLineas();
  }, []);

  useEffect(() => {
    if (lineas.length > 0) {
      setCurrentLinea(lineas[0].id); // Set the first linea as the current linea
      setLoading(false);
    }
  }, [lineas]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchLineas = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate=icono_landing,series,series.imagen_landing`);
      const data = await response.json();
      if (data && data.data) {
        const fetchedLineas = data.data.map(item => ({
          id: item.id,
          nombre: item.attributes.nombre,
          image: item.attributes.icono_landing?.data?.attributes?.url || "", 
          series: item.attributes.series?.data.map(serie => ({
            id: serie.id,
            nombre: serie.attributes.nombre,
            image: serie.attributes.imagen_landing?.data?.[0]?.attributes?.url || ""  
          })) || []
        }));
        setLineas(fetchedLineas);
      } else {
        console.error("Lineas data is not structured as expected:", data);
      }
    } catch (error) {
      console.error("Error fetching lineas:", error);
    }
  };

  const handleLineaChange = (index) => {
    const selectedLinea = lineas[index];
    if (selectedLinea) {
      setCurrentLinea(selectedLinea.id);
      if (serieSliderRef.current) {
        serieSliderRef.current.slickGoTo(0);
      }
    }
  };

  const currentLineaSeries = lineas.find(linea => linea.id === currentLinea)?.series || [];

  return (
    <div className="home-showcase-section-container">
      {isMobile ? (
        <HomeShowcaseMobile
          lineas={lineas}
          currentLinea={currentLinea}
          currentLineaSeries={currentLineaSeries}
          handleLineaChange={handleLineaChange}
          lineaSliderRef={lineaSliderRef}
          serieSliderRef={serieSliderRef}
          loading={loading}
        />
      ) : (
        <HomeShowcaseDesktop
          lineas={lineas}
          currentLinea={currentLinea}
          currentLineaSeries={currentLineaSeries}
          handleLineaChange={handleLineaChange}
          lineaSliderRef={lineaSliderRef}
          serieSliderRef={serieSliderRef}
          loading={loading}
        />
      )}

      {loading && <div>Loading...</div>}
    </div>
  );
}

export default HomeShowcaseSection;
