import React from 'react';
import './ModelModal.css';

function ModelModal({ specs, onClose, name }) {
  return (
    <>
      <div className="model-modal-overlay" onClick={onClose}></div> {/* Background Overlay */}
      <div className="model-modal-content">
        <h2 className="model-modal-title">Especificaciones de {name}</h2>
        <table className="model-modal-specs-table">
          <thead>
            <tr>
              <th>Especificación</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {specs.length > 0 ? (
              specs.map((spec, index) => (
                <tr key={index}>
                  <td>{spec.name}</td>
                  <td>{spec.value}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No hay especificaciones disponibles</td>
              </tr>
            )}
          </tbody>
        </table>
        <button className="model-modal-close-button" onClick={onClose}>Cerrar</button>
      </div>
    </>
  );
}

export default ModelModal;
