// src/components/HomeBannerMobile/HomeBannerMobile.js

import React from 'react';
import Slider from 'react-slick';
import './HomeBannerMobile.css';

// Image imports
import bannerImage1 from '../../../../images/HomeBannerMobile/home-banner-mobile-1.webp';
import bannerImage2 from '../../../../images/HomeBannerMobile/home-banner-mobile-2.webp';
import bannerImage3 from '../../../../images/HomeBannerMobile/home-banner-mobile-3.webp';
import HomeActionButton from '../../HomeActionButton'; // Import the HomeActionButton

const HomeBannerMobile = ({ scrollToContact }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable arrows
    autoplay: true, // Autoplay the slides
    autoplaySpeed: 5000, // Change every 5 seconds
    fade: true, // Fade transition between slides
  };

  return (
    <div className="home-banner-mobile-container">
      <Slider {...settings}>
        <div className="home-banner-mobile-slide">
          <img src={bannerImage1} alt="Home banner mobile 1" className="home-banner-mobile-image" />
        </div>
        <div className="home-banner-mobile-slide">
          <img src={bannerImage2} alt="Home banner mobile 2" className="home-banner-mobile-image" />
        </div>
        <div className="home-banner-mobile-slide">
          <img src={bannerImage3} alt="Home banner mobile 3" className="home-banner-mobile-image" />
        </div>
      </Slider>

      {/* Content that stays on top of the carousel */}
      <div className="home-banner-mobile-content">
        <h2 className="home-banner-mobile-vision-label">VISIÓN QUE CREA FUTURO</h2>
        <p className="home-banner-mobile-vision-sublabel">
          Maquinaria de alta calidad que integran tecnología, experiencia y sustentabilidad.
        </p>

      </div>
      <div className="home-banner-mobile-action-container">
          <HomeActionButton onClick={scrollToContact} />
        </div>
    </div>
  );
};

export default HomeBannerMobile;
