import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './FullPageWrapper.css';

const FullPageWrapper = ({ children }) => {
  return (
    <ReactFullpage
      licenseKey={'OPEN-SOURCE-GPLV3-LICENSE'}
      scrollingSpeed={1000}
      afterRender={() => {
        // Disparar el evento sectionChange al montarse, indicando que está en la sección 1 (índice 0)
        const sectionChangeEvent = new CustomEvent('sectionChange', { detail: 0 });
        document.dispatchEvent(sectionChangeEvent);
      }}
      onLeave={(origin, destination) => {
        // Disparar el evento sectionChange al cambiar de sección, con el índice de la nueva sección
        const sectionChangeEvent = new CustomEvent('sectionChange', { detail: destination.index });
        document.dispatchEvent(sectionChangeEvent);
      }}
      render={({ fullpageApi }) => (
        <ReactFullpage.Wrapper>
          {React.Children.map(children, child =>
            React.cloneElement(child, { fullpageApi })
          )}
        </ReactFullpage.Wrapper>
      )}
    />
  );
};

export default FullPageWrapper;
