import React from 'react';
import ModelCard from './ModelCard';
import './ProductGrid.css';

function ProductGrid({ models, loading, lineaName, serieName }) {
  return (
    <div className="product-grid-container">
      {/* Breadcrumb */}
      <div className="product-grid-breadcrumb">
        <p>
          <span className="linea-name">{lineaName}</span> / <span className="serie-name">{serieName}</span>
        </p>
      </div>

      {/* Product grid */}
      <div className="product-grid">
        {loading ? (
          <div className="product-grid-message">Cargando modelos...</div>
        ) : models.length === 0 ? (
          <div className="product-grid-message">No hay modelos disponibles para la serie seleccionada.</div>
        ) : (
          models.map((model) => (
            <ModelCard
              key={model.id}
              id={model.id}
              name={model.name}
              specs={model.specs}
              image={model.image}
              slug={model.slug}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ProductGrid;
