import React, { useState } from 'react';
import './ServiciosAccordeon.css'; // Styles for the accordion

const ServiciosAccordeon = () => {
  // State to track which accordion section is active
  const [activeAccordion, setActiveAccordion] = useState(1);

  // Image paths corresponding to each accordion section
  const images = {
    1: '/images/servicios/servicios-accordeon-1.png',
    2: '/images/servicios/servicios-accordeon-2.png',
    3: '/images/servicios/servicios-accordeon-3.png',
    4: '/images/servicios/servicios-accordeon-4.png'
  };

  // Function to toggle accordion sections
  const toggleAccordion = (index) => {
    setActiveAccordion(index);
  };

  return (
    <div className="servicios-accordeon-wrapper">
      <div className="servicios-accordeon-content">
        {/* Accordion Titles and Content */}
        <div className="servicios-accordeon-left">
          <div
            className={`servicios-accordeon-item ${activeAccordion === 1 ? 'active' : ''}`}
            onClick={() => toggleAccordion(1)}
          >
            <h3>Servicios Integrales</h3>
            {activeAccordion === 1 && (
              <p>
                Zoomlion siempre otorga gran importancia al servicio en cada una de las etapas para garantizar el funcionamiento continuo y estable de nuestras máquinas, con el fin de lograr su máxima utilización y productividad. 
                En combinación con nuestro lema, ofrecemos un servicio estándar y un servicio de valor agregado para satisfacer todas tus necesidades.
              </p>
            )}
          </div>

          <div
            className={`servicios-accordeon-item ${activeAccordion === 2 ? 'active' : ''}`}
            onClick={() => toggleAccordion(2)}
          >
            <h3>Soporte Técnico</h3>
            {activeAccordion === 2 && (
              <p>
                Zoomlion está comprometido a brindar soporte técnico de primera clase. Nuestros representantes técnicos tienen un conocimiento profesional de la construcción, por lo que puedes encontrar respuestas a tus preguntas desde los distribuidores locales, los centros de servicio regionales, hasta nuestra sede central.
              </p>
            )}
          </div>

          <div
            className={`servicios-accordeon-item ${activeAccordion === 3 ? 'active' : ''}`}
            onClick={() => toggleAccordion(3)}
          >
            <h3>Capacitación</h3>
            {activeAccordion === 3 && (
              <p>
                La capacitación es otro elemento fundamental de ZOOMLION CARE. Con el fin de asegurar que los clientes comprendan plenamente los productos de Zoomlion y transmitir los conocimientos relevantes a los operadores de las máquinas, Zoomlion ofrece una gama de apoyo en formación. Participar en las capacitaciones de Zoomlion te ayudará a aprender cómo mantener la maquinaria de manera segura, efectiva y eficiente, mejorando así la productividad del equipo, prolongando su vida útil y obteniendo el máximo retorno de tu inversión. Nuestros cursos de formación pueden realizarse en nuestra sede central, en uno de nuestros Centros de Capacitación Regionales, en uno de nuestros distribuidores, o incluso en el lugar de trabajo. También ofrecemos capacitación a medida (en diferentes niveles) utilizando varios métodos de enseñanza (teoría, práctica, en línea y simuladores) para un aprendizaje óptimo. Para más información sobre la capacitación, por favor contacta a tu distribuidor local de Zoomlion.
              </p>
            )}
          </div>

          <div
            className={`servicios-accordeon-item ${activeAccordion === 4 ? 'active' : ''}`}
            onClick={() => toggleAccordion(4)}
          >
            <h3>Equipo de Servicio</h3>
            {activeAccordion === 4 && (
              <p>
                Además de nuestro notable desarrollo en la industria de la maquinaria de construcción, hemos participado activamente en iniciativas filantrópicas, como la reconstrucción post-desastres, donaciones educativas, reingeniería de la sociedad rural y apoyo a los niños que permanecen en casa, lo que nos ha ganado amplio reconocimiento de la sociedad y los medios. 
                Durante la década transcurrida desde agosto de 2003, la donación educativa de 1 millón de yuanes "El amor cambia el destino", iniciada por nosotros, ha donado más de 2 millones de dólares estadounidenses para apoyar directamente a 1,769 estudiantes necesitados que ingresan a la universidad en China, y ha otorgado la Beca de Auto-Motivación de Zoomlion a 868 excelentes estudiantes necesitados. 
                En 2012, donamos 330,000 dólares para apoyar a 686 estudiantes necesitados que ingresaban a la universidad y premiamos a 80 estudiantes auto-motivados.
              </p>
            )}
          </div>
        </div>

        {/* Right Side Image */}
        <div className="servicios-accordeon-right">
          <img
            src={images[activeAccordion]}
            alt={`Accordion Section ${activeAccordion}`}
            className="servicios-accordeon-image"
          />
        </div>
      </div>
    </div>
  );
};

export default ServiciosAccordeon;
