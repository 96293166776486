// src/components/HomeNoticiasSectionDesktop.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeNoticiasSectionDesktopCard from './HomeNoticiasSectionDesktopCard';
import './HomeNoticiasSectionDesktop.css';

const HomeNoticiasSectionDesktop = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticias = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/noticias?populate=*`);
      const data = await response.json();
      
      const noticiasArray = data.data.map((noticia) => ({
        id: noticia.id,
        ...noticia.attributes,
        previsualizacion: noticia.attributes.previsualizacion?.data?.[0]?.attributes?.url || '',
        fechaPublicacion: noticia.attributes.publishedAt,
        slug: noticia.attributes.slug,
      }));
      setNoticias(noticiasArray.slice(0, 3)); // Tomamos solo las primeras 3 noticias
    };

    fetchNoticias();
  }, []);

  return (
    <div className="home-noticias-section-desktop">
      <div className="home-noticias-section-desktop-container">
        <h2 className="home-noticias-section-desktop-title">Noticias</h2>
        <div className="home-noticias-section-desktop-grid">
          {noticias.map((noticia) => (
            <HomeNoticiasSectionDesktopCard key={noticia.id} noticia={noticia} />
          ))}
        </div>
        {/* Botón Ver Más */}
        <div className="home-noticias-section-desktop-ver-mas-wrapper">
          <Link to="/noticias" className="home-noticias-section-desktop-ver-mas">
            VER MÁS
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeNoticiasSectionDesktop;
