// src/components/Modelo/ModeloDestacadoDesktop/ModeloDestacadoDesktop.js
import React, { useEffect, useRef, useState } from 'react';
import ModeloDestacadoDesktopCard from './ModeloDestacadoDesktopCard';
import './ModeloDestacadoDesktop.css'; 

const ModeloDestacadoDesktop = ({ linea }) => {
  const [destacados, setDestacados] = useState([]);
  const [error, setError] = useState(null);
  const cardRef = useRef([]); // Ref para las tarjetas

  useEffect(() => {
    const fetchDestacados = async () => {
      if (!linea || !linea.id) {
        setError('No se pudo encontrar la línea seleccionada.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?filters[id]=${linea.id}&populate=destacados.serie,destacados.imagen_card`);
        const data = await response.json();

        if (!response.ok || !data.data || data.data.length === 0) {
          throw new Error('Error fetching destacados');
        }

        const destacadosData = data.data[0].attributes.destacados.data.map(destacado => destacado.attributes);
        setDestacados(destacadosData.slice(0, 3));  // Limitamos a los 3 primeros
      } catch (error) {
        console.error('Error fetching destacados:', error);
        setError('Error loading destacados.');
      }
    };

    fetchDestacados();
  }, [linea]);

  useEffect(() => {
    // IntersectionObserver para animación al hacer scroll
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.2 }
    );

    cardRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, [destacados]);

  if (error) {
    return <div className="modelo-destacado-desktop-error">{error}</div>;
  }

  return (
    <div className="modelo-destacado-section">
    <div className="modelo-destacado-container">
      <h3 className="modelo-destacado-title">Productos Recomendados</h3>
      <div className="modelo-destacado-desktop">
        {destacados.length > 0 ? (
          destacados.map((modelo, index) => (
            <div
              key={index}
              className="modelo-destacado-card-wrapper"
              ref={(el) => (cardRef.current[index] = el)} // Asignamos ref para cada tarjeta
            >
              <ModeloDestacadoDesktopCard 
                modelo={modelo.nombre}
                slug={modelo.slug}
                serie={modelo.serie?.data?.attributes?.nombre}  
                imagenCard={modelo.imagen_card?.data?.attributes?.url}  
              />
            </div>
          ))
        ) : (
          <div>No hay modelos destacados.</div>
        )}
      </div>
    </div>
    </div>
  );
};

export default ModeloDestacadoDesktop;
