import React, { useState, useEffect } from 'react';
import HomeContactoSectionDesktop from './HomeContactoSectionDesktop/HomeContactoSectionDesktop';
import HomeContactoSectionMobile from './HomeContactoSectionMobile/HomeContactoSectionMobile';
import './HomeContactoSection.css'; // Asegúrate de tener las clases home-contacto-section-

const HomeContactoSection = () => {
  const [selectedCountry, setSelectedCountry] = useState(null); // Estado para el país seleccionado
  const [countries, setCountries] = useState([]);  // Estado para almacenar los países y oficinas
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detectar si es móvil o no

  // Efecto para ajustar el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Efecto para cargar las oficinas al montar el componente
  useEffect(() => {
    const fetchOficinas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/oficinas?populate=*`);
        const result = await response.json();

        if (Array.isArray(result.data)) {
          const formattedData = result.data.map((oficina) => {
            // Obtener la latitud y longitud de d3map si están presentes
            const d3mapCoords = oficina.attributes.d3map && oficina.attributes.d3map.length > 0
              ? {
                  latitud: oficina.attributes.d3map[0].latitud,
                  longitud: oficina.attributes.d3map[0].longitud
                }
              : {
                  latitud: null,
                  longitud: null
                };

            return {
              name: oficina.attributes.pais,
              email: oficina.attributes.correo,
              direccion: oficina.attributes.direccion,
              representante: oficina.attributes.representante,
              telefono: oficina.attributes.telefono,
              ...d3mapCoords // Añadir latitud y longitud de d3map
            };
          });

          setCountries(formattedData);
          setLoading(false);

          // Seleccionar el primer país por defecto si no hay ninguno seleccionado
          if (formattedData.length > 0) {
            setSelectedCountry(formattedData[0]);
          }
        }
      } catch (error) {
        console.error('Error al obtener las oficinas:', error);
        setLoading(false);
      }
    };

    fetchOficinas();
  }, []);

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  if (loading) {
    return <div className="home-contacto-section-cargando">Cargando...</div>;
  }

  return (
    <div className="home-contacto-section-container">
      {isMobile ? (
        <HomeContactoSectionMobile
          onCountrySelect={handleCountrySelect}
          selectedCountry={selectedCountry}
          countries={countries}
        />
      ) : (
        <HomeContactoSectionDesktop
          onCountrySelect={handleCountrySelect}
          selectedCountry={selectedCountry}
          countries={countries}
        />
      )}
    </div>
  );
};

export default HomeContactoSection;
