// src/components/Noticias/NoticiaGrid.js
import React from 'react';
import NoticiaCard from './NoticiaCard';
import './NoticiaGrid.css';

const NoticiaGrid = ({ noticias }) => {
  return (
    <div className="noticia-grid-container">
      {noticias.map((noticia) => (
        <NoticiaCard key={noticia.id} noticia={noticia} />
      ))}
    </div>
  );
};

export default NoticiaGrid;
