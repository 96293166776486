// src/components/Noticias/NoticiaCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NoticiaCard.css';

const NoticiaCard = ({ noticia }) => {
  return (
    <div className="noticia-card-container">
      <div className="noticia-card-image-container">
        {noticia.previsualizacion ? (
          <img src={noticia.previsualizacion} alt={noticia.titulo} className="noticia-card-image" />
        ) : (
          <div className="noticia-card-placeholder">No Image Available</div> // Placeholder si no hay previsualización
        )}
      </div>
      <h3 className="noticia-card-title">{noticia.titulo}</h3>
      
      {/* Botón que redirige a la página de detalles de la noticia */}
      <div className="noticia-card-button-wrapper">
        <Link to={`/noticias/${noticia.slug}`} className="noticia-card-button">
          Leer más
        </Link>
      </div>
    </div>
  );
};

export default NoticiaCard;
