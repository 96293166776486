import React from 'react';
import './OfertaCard.css';

const OfertaCard = ({ convocatoria, onPostular }) => {
  return (
    <div className="oferta-card-container">
      <div className="oferta-card-header">
        <span className="oferta-card-icono-localizacion" />
        <h3 className="oferta-card-title">{convocatoria.titulo}</h3>
      </div>
      <div className="oferta-card-details">
        <ul>
          <li>País: {convocatoria.pais}</li>
          <li>Ciudad: {convocatoria.ciudad}</li>
          <li>Plazo de cierre: {convocatoria.fin}</li>
        </ul>
        <button
          className="oferta-card-postular-boton"
          onClick={() => onPostular(convocatoria)}
        >
          Postular
        </button>
      </div>
    </div>
  );
};

export default OfertaCard;
