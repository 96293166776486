import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LazyImage from '../LazyImage';  // Import LazyImage
import './ModelCard.css';
import ModelModal from './ModelModal';  // Import the new ModelModal

function ModelCard({ slug, name, specs, image }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    // GA4 event for clicking on "Detalles"
    window.gtag('event', 'view_details', {
      event_category: 'engagement',
      event_label: `Detalles de ${name}`,
      value: 1
    });

    setModalOpen(!isModalOpen);
  };

  const handleVerMasClick = () => {
    // GA4 event for clicking on "Ver Más"
    window.gtag('event', 'view_more', {
      event_category: 'navigation',
      event_label: `Ver más de ${name}`,
      value: 1
    });
  };

  return (
    <div className="model-card">
      <LazyImage src={image} alt={name} className="model-card-image" />
      <h3 className="model-card-name">{name}</h3>
      <ul className="model-card-specs">
        {specs.length > 0 ? (
          specs.map((spec, index) => (
            <li key={index}>
              <span className="model-card-spec-name">{spec.name}:</span>{spec.value}
            </li>
          ))
        ) : (
          <li className="no-specs-message">No hay especificaciones disponibles</li>
        )}
      </ul>
      <div className="model-card-footer">
        <Link
          to={`/modelo/${slug}`}
          className="model-card-button vermas-button"
          onClick={handleVerMasClick}  // GA4 event for "Ver Más"
        >
          VER MÁS
        </Link>
        <button className="model-card-button modal-button" onClick={toggleModal}>
          DETALLES
        </button>
      </div>

      {isModalOpen && (
        <ModelModal 
          specs={specs} 
          onClose={toggleModal} 
          name={name}
        />
      )}
    </div>
  );
}

export default ModelCard;
